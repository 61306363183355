$font-family: franklin-gothic-urw, serif

$light:     #fafafa
$dark:      #1F2125
$border:    #E0E0E0
$gray:      #D9D9D9
$text-gray: #6F7881
$dark-gray: #36383B

$notice-fg:     #856404
$notice-bg:     #fff3cd
$notice-border: #ffeeba

$parties: ()
@import "/election/variables"

$screen-phone-max:    768px
$screen-tablet-max:   992px
$screen-laptop-max:   1200px
$screen-desktop-max:  1440px

=phone
  @media only screen and (max-width: $screen-phone-max)
    @content

=tablet
  @media only screen and (max-width: $screen-tablet-max)
    @content

=laptop
  @media only screen and (max-width: $screen-laptop-max)
    @content

=desktop
  @media only screen and (max-width: $screen-desktop-max)
    @content

=constrain
  margin: 0 auto
  max-width: 1000px
  +laptop
    max-width: $screen-tablet-max
  +tablet
    max-width: $screen-phone-max
