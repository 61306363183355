@import 'variables'
.ridings
  display: flex
  flex-direction: column
  width: 100%
  min-height: 100vh
  min-height: calc(100 * var(--vh, 1vh))

  header
    display: flex
    justify-content: center
    align-items: center
    background: white
    color: $dark

    font-size: 1.5em
    height: 82px
    transition: all 0.33s ease-in-out
    box-shadow: inset 0 -1px 1px 0 $border

    svg
      display: inline
      height: 1em

    &:only-child
      flex: 1
      flex-direction: row
      font-size: 3em
      height: 100vh
      height: calc(100 * var(--vh, 1vh))

  main
    display: flex
    flex-direction: column
    flex: 1
    min-height: calc(100vh - 82px + 1px)
    min-height: calc(100 * var(--vh, 1vh) - 82px + 1px)
    transition: min-height 0.25s ease-in
    background: $light
    border-bottom: 1px solid $border

    .riding-selector
      height: 100px
      background: white
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      border-bottom: 1px solid $border

      .Select-control
        text-align: center
        border: 1px solid $border !important
        box-shadow: none !important
        background: white
        border-radius: 8px
        height: 45px
        max-width: 500px
        +phone
          max-width: 360px

      .Select-value
        font-size: 20px
        line-height: 45px
        font-family: $font-family
        font-style: normal
        font-weight: 500

    .reco
      flex: 1
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      img.party
        width: 350px
        max-width: 70vw
        margin: 0 auto
        height: auto

      .result-text
        font-family: $font-family
        text-align: center
        display: block
        font-size: 50px
        +phone
          font-size: 36px
        line-height: 1.1em
        font-weight: 700
        color: $dark

        small
          color: $text-gray
          font-size: 20px
          line-height: 1.4em
          font-weight: 500
          text-transform: uppercase
          display: block
          margin-bottom: 1.5em

        .br
          display: inline
          +phone
            display: block

        img
          display: block

      position: relative
      .notice
        position: absolute
        top: 0
        width: 100%
        text-align: center
        padding: 1.25rem
        color: $notice-fg
        background-color: $notice-bg
        border: 1px 0 solid $notice-border

    .chart
      +constrain
      +phone
        padding: 0 1em
      height: 250px
      margin-top: -100px
      width: 100%

      .my-tooltip
        background: $dark
        color: $light
        padding: 0.8em 1em
        border-radius: 8px
        transform: translate(-66%, -150%)


  section.faq
    +constrain
    background: white
    display: flex
    flex-direction: column
    margin: 0 auto
    padding: 40px 0
    +phone
      padding-left: 2em
      padding-right: 2em

    img
      vertical-align: text-bottom

    .list
      table
        width: 100%
        border-spacing: 0
        thead
          display: none
        color: $dark
        tr:nth-child(even)
          background-color: $light
        td
          padding: 1.33em 0.66em
          &.Anyone
            opacity: 0.33
          @each $name, $color in $parties
            &.#{$name}::before
              display: inline-block
              content: ' '
              width: 0.6em
              height: 0.6em
              border-radius: 0.3em
              margin-right: 0.5em
              background-color: $color
              color: $color
              @if lightness($color) > 75%
                color: darken($color, 33%)

  footer
    display: flex
    flex-direction: column
    justify-content: space-between

    font-family: $font-family

    background: $dark
    color: $light
    a, svg
      opacity: 0.5
      transition: all 0.15s
      &:hover
        opacity: 1

    > *
      width: 100%

    .top
      +constrain
      +phone
        padding: 0 1.5em
      display: flex
      justify-content: space-between
      align-items: center
      min-height: 100px
      +phone
        min-height: 80px

      > svg
        height: 25px
        opacity: 1
        +phone
          height: 20px

      .share
        display: flex
        flex-direction: row
        div
          cursor: pointer
          &:hover
            color: $light
          svg
            height: 24px
            width: 24px
            margin: 0 58px 0 0
            +phone
              height: 32px
              width: 32px
              margin: 8px

          &:last-of-type
            svg
              margin-right: 0

    .bottom
      +constrain
      +phone
        padding: 0 1.5em
      border-top: 1px solid $dark-gray
      min-height: 100px

      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      font-size: 20px
      font-weight: 500

      a, a:hover, a:visited, span
        color: inherit
        text-decoration: none
        cursor: pointer

      .links
        flex: 1
        display: flex
        justify-content: space-between
        +phone
          display: flex
          flex-direction: column
          a
            line-height: 2em
            &:first-of-type
              margin-top: 1em
            &:last-of-type
              margin-bottom: 1em
            padding: 0
            border: 0
      .languageSelector
        width: 200px
        text-align: right
