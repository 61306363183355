@import 'variables'
.Chart
  height: 250px
  display: flex
  justify-content: space-between
  align-items: flex-end
  position: relative

  .bar::before
    opacity: 0
    top: 0

    transition: all 0.2s ease-out
  &:hover, &.hover
    .bar::before
      top: -1em
      opacity: 1

  .bar
    transition: all 0.3s ease-out
    &::before
      display: block
      content: attr(data-percent)
      position: relative
      width: 100%
      text-align: center
      line-height: 0
      font-weight: bold
      font-size: 1.2em

    @each $name, $color in $parties
      &.#{$name}
        background-color: $color
        &:before
          color: $color
          @if lightness($color) > 75%
            color: darken($color, 33%)
